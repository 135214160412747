//page container that accepts children and renders them
import React from "react";
import { Flex, useColorModeValue } from "@chakra-ui/react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";

interface Props {
  children: React.ReactNode;
  childrenPaddingY?: string | number | string[] | number[];
  childrenPaddingX?: any;
}
const PageContainer = ({
  children,
  childrenPaddingY = 0,
  childrenPaddingX,
}: Props) => {
  const bg = useColorModeValue("white", "#071E22");
  return (
    <>
      <Flex bg={bg} w="100%" minH="100vh" h="auto" p={{ base: 5, md: 10 }}>
        <Flex flexDir="column" w={"100%"}>
          <NavBar />
          <Flex
            px={childrenPaddingX}
            py={childrenPaddingY}
            flexDir="column"
            w={"100%"}
          >
            {children}
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
};

export default PageContainer;
