import axios from "axios";
import { QueryClient } from "react-query";
// import { useToast } from "@chakra-ui/react";

// axios.defaults.withCredentials = true;

export const API_URL = process.env.REACT_APP_API_URL;

export const queryClient = new QueryClient();

export const blogPosts = async () => {
  const res = await axios.get(`${API_URL}/api/posts`);
  return res.data;
}

export const blogPost = async (id: string) => {
  const res = await axios.get(`${API_URL}/api/posts/${id}`);
  return res.data;
} 