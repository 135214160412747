import React from "react";
import { Button as ChakraButton, useColorModeValue } from "@chakra-ui/react";

type Props = {
  onClick: () => void;
  children: React.ReactNode;
  styles?: any;
};

const Button = ({ children, onClick, styles }: Props) => {
  const bg = useColorModeValue("#F5FBEF", "#013220");
  const textColor = useColorModeValue("#A1C520", "#B3BF85");
  return (
    <ChakraButton
      _hover={{bg: 'gray.200', color: '#013220'}}
      alignSelf={{ base: "center", md: "flex-start" }}
      width={{ base: "100%", md: 200 }}
      mt={{ base: 10, md: 5 }}
      h={50}
      {...styles}
      color={textColor}
      bg={bg}
      onClick={onClick}
      fontWeight="800"
    >
      {children}
    </ChakraButton>
  );
};

export default Button;
