import {
  Box,
  Heading,
  Link,
  Text,
  Wrap,
  WrapItem,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import BlogTags from "./BlogTags";
// import { API_URL } from "../../api";
// import { useEffect, useState } from "react";

const BlogCard = ({
  title,
  postMeta: { description },
  category,
  postImage,
  id,
  author,
  createdAt,
}: BlogPost) => {
  const navigate = useNavigate();

  //UNCOMMENT ONCE BUG IS FIXED 
  
  // const [loading, setLoading] = useState<boolean>(true);
  // const [validImg, setValidImg] = useState<boolean | null>(null);

  // const imgUrl =
  //   process.env.NODE_ENV === "production"
  //     ? API_URL + postImage.url
  //     : postImage.url;

  // useEffect(() => {
  //   fetch(imgUrl).then((res) => {
  //     setValidImg(res.status === 200);
  //     console.log(imgUrl)
  //     setLoading(false);
  //   });
  // }, [imgUrl]);
  return (
    <>
      <Wrap h="100%" w="100%">
        <WrapItem
          h="100%"
          onClick={() => navigate(id)}
          _hover={{ cursor: "pointer" }}
          width={"100%"}
        >
          <Box w="100%" h="100%">
            <Box rounded={10} overflow="hidden">
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                {/* {!loading || validImg ? (
                  <Image
                    transform="scale(1.0)"
                    src={imgUrl}
                    alt="some text"
                    width={300}
                    h={300}
                    transition="0.3s ease-in-out"
                    _hover={{
                      transform: "scale(1.05)",
                    }}
                  />
                ) : null} */}
              </Link>
            </Box>
            <BlogTags tags={[category.name]} marginTop="3" />
            <Heading fontSize="xl" marginTop="2">
              <Link textDecoration="none" _hover={{ textDecoration: "none" }}>
                {title}{" "}
                <span style={{ fontSize: 15 }}>
                  - {new Date(createdAt).toLocaleDateString()}
                </span>
              </Link>
            </Heading>
            <Text as="p" fontSize="md" marginTop="2">
              {description}
            </Text>
            {/* <BlogAuthor name={author.name} date={new Date(createdAt)} /> */}
          </Box>
        </WrapItem>
      </Wrap>
    </>
  );
};

export default BlogCard;
