import React from "react";
import { Flex, Heading, Image, Text } from "@chakra-ui/react";
import { useGreen, useLightGreen } from "../utils/theme";
import Button from "../components/Button";

const Banner = () => {
  const lightGreen = useLightGreen();
  const green = useGreen();
  return (
    <>
      <Flex
        rounded={10}
        w={"100%"}
        h={500}
        bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
        justify={"space-between"}
      >
        <Flex
          w="100%"
          justifyContent={{ base: "space-between", md: "space-between" }}
          flexDir={"column"}
          p={{ base: 10, md: 20 }}
        >
          <Heading
            color="white"
            fontWeight={600}
            w={{ base: "100%", md: 400 }}
            fontSize={60}
            // fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"110%"}
            
          >
            LISTEN{" "}
            <Text fontWeight={"200"} as={"span"}>
              AND
            </Text>{" "}
            GROW{" "}
            <Text fontWeight={"200"} as={"span"}>
              WITH
            </Text>{" "}
            PAPA{" "}
          </Heading>
          <Flex flexDir={{ base: "column", md: "row" }}>
            <Button onClick={() => window.location.href = 'https://linktr.ee/gwppodcast'}>Listen Now</Button>
            {/* <Button
              _hover={{ bg: "black", color: "white" }}
              bg="white"
              color={green}
              mt={{ base: 5, md: 0 }}
              ml={{ base: 0, md: 10 }}
            >
              Resources
            </Button> */}
          </Flex>
        </Flex>
        <Image
          w={{ base: "40%" }}
          display={{ base: "none", lg: "flex" }}
          src={require("../assets/images/banner_img.png")}
        />
      </Flex>
    </>
  );
};

export default Banner;
