import { useColorModeValue } from "@chakra-ui/react";
import { extendTheme } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import "@fontsource/source-sans-pro";

export const useLightGreen = () => {
  return useColorModeValue("#F5FBEF", "#B3BF85");
};
export const useGreen = () => {
  return useColorModeValue("#A1C520", "#5F7411");
};

const theme = extendTheme({
  colors: {
    transparent: "transparent",
    light: "#013220",
  },
  fonts: {
    heading: `'Montserrat', sans-serif`,
    p: `'Source Sans Pro', sans-serif`,
    footer: `'Inter', sans-serif`,
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
  },
});

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Versatylo Rounded';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: url('./fonts/versatylo-rounded.ttf') format('tff');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
      }
    `}
  />
);
export { Fonts };

export default theme;
