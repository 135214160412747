import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "@fontsource/montserrat/400.css";
import "@fontsource/montserrat/100.css";
import "@fontsource/montserrat/700.css";
import "@fontsource/inter/400.css";
import theme, { Fonts } from "./utils/theme";
import { QueryClientProvider } from "react-query";
import { queryClient } from "./api";


//pages 
import App from "./Pages/App";
import About from "./Pages/About";
import Episodes from "./Pages/Episodes";
import Blog from "./Pages/blog";
import BlogPost from "./Pages/blog/Post";


const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ChakraProvider theme={theme}>
        <Fonts />
        <Router>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about" element={<About />} />
            <Route path="/episodes" element={<Episodes />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogPost />} />
          </Routes>
        </Router>
      </ChakraProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
