import Banner from "../components/Banner";
import Hero1 from "../components/Hero1";
import Hero2 from "../components/Hero2"
import Hero3 from "../components/Hero3";
import PageContainer from "../components/PageContainer";


const App = () => {
  return (
    <>
      <PageContainer>
        <Banner />
        <Hero1 />
        <Hero2 />
        <Hero3 />
      </PageContainer>
    </>
  );
};

export default App;
