import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import { API_URL, blogPost } from "../../api";
import { Serialize } from "../../utils/serialize";
import {
  Avatar,
  Flex,
  HStack,
  Heading,
  Image,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import PageContainer from "../../components/PageContainer";

const Post = () => {
  const { id } = useParams();
  const { data: postData } = useQuery<BlogPost>({
    queryKey: ["post", id],
    queryFn: () => blogPost(id!),
  });

  const color = useColorModeValue('black', 'white')

  return (
    <PageContainer childrenPaddingX={{base: 4, sm: 20, lg: 40}} childrenPaddingY={20}>
      {postData && (
        <>
          <Heading>{postData.postMeta.title}</Heading>
          <HStack spacing={4} mt={10} mb={10}>
            <Avatar color="black" bg={"gray.400"} name={postData.author.name} />
            <Flex flexDir={"column"}>
              <Text color={color} fontWeight={"500"}>
                {postData.author.name}
              </Text>
              <Text fontWeight={"500"} color="gray.400" alignSelf={"start"}>
                {new Date(postData.createdAt).toLocaleDateString("en-us", {
                  month: "long",
                })}
              </Text>
            </Flex>
          </HStack>
          <Image
            src={
              "https://www.shutterstock.com/image-photo/bloggingblog-concepts-ideas-white-worktable-600nw-1029506242.jpg"
              // process.env.NODE_ENV === "production"
              //   ? API_URL + postData.postImage.url
              //   : postData.postImage.url
            }
            rounded={5}
            h={400}
          />

          <Flex flexDir={"column"} mt={10}>
            {postData.layout.map((block, index) => {
              switch (block.blockType) {
                case "content":
                  return <Serialize content={block.content} key={index} />;
                default:
                  return "Text";
              }
            })}
          </Flex>
        </>
      )}
    </PageContainer>
  );
};

export default Post;
