import React, { Fragment } from "react";
import {
  Code,
  Image,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { API_URL } from "../api";

type SerializeFunction = React.FC<{
  content?: Node[];
}>;

const isText = (value: any): boolean =>
  typeof value === "object" && value !== null && typeof value.text === "string";

export const Serialize: SerializeFunction = ({ content }) => {
  return (
    <Fragment>
      {content?.map((node: any, i) => {
        if (isText(node)) {
          let text = <Text lineHeight={8}>{node.text}</Text>;

          if (node.bold) {
            text = (
              <Text as="b" key={i}>
                {text}
              </Text>
            );
          }

          if (node.code) {
            text = <Code key={i}>{text}</Code>;
          }

          if (node.italic) {
            text = (
              <Text as="em" key={i}>
                {text}
              </Text>
            );
          }
          if (node.text === "") {
            text = <br key={i} />;
          }
          return <Fragment key={i}>{text}</Fragment>;
        }
        if (!node) {
          return null;
        }

        switch (node.type) {
          case "link":
            return (
              <Link key={i} style={{ textDecoration: "underline" }} href={node.url}>
                <Serialize content={node.children} />
              </Link>
            );
          case "upload":
            return (
              <Image
                w={400}
                h={400}
                src={process.env.NODE_ENV === 'production' ? API_URL + node.value.url : node.value.url}
                key={i}
              />
            );
          case "br":
            return <br key={i} />;
          case "h1":
            return (
              <Text fontSize="5xl" key={i}>
                <Serialize content={node.children} />
              </Text>
            );
          case "h2":
            return (
              <Text fontSize="4xl" key={i}>
                <Serialize content={node.children} />
              </Text>
            );
          case "h3":
            return (
              <Text fontSize="3xl" key={i}>
                <Serialize content={node.children} />
              </Text>
            );
          case "h4":
            return (
              <Text fontSize="2xl" key={i}>
                <Serialize content={node.children} />
              </Text>
            );
          case "h5":
            return (
              <Text fontSize="xl" key={i}>
                <Serialize content={node.children} />
              </Text>
            );
          case "h6":
            return (
              <Text fontSize="lg" key={i}>
                <Serialize content={node.children} />
              </Text>
            );
          case "quote":
            return (
              <blockquote key={i}>
                <Serialize content={node.children} />
              </blockquote>
            );
          case "ul":
            return (
              <UnorderedList key={i}>
                <Serialize content={node.children} />
              </UnorderedList>
            );
          case "ol":
            return (
              <OrderedList key={i}>
                <Serialize content={node.children} />
              </OrderedList>
            );
          case "li":
            return (
              <ListItem key={i}>
                <Serialize content={node.children} />
              </ListItem>
            );
          default:
            return (
              <Text fontFamily={'Source Sans Pro'} fontSize={18} key={i}>
                <Serialize content={node.children} />
              </Text>
            );
        }
      })}
    </Fragment>
  );
};

// export const Serialize: SerializeFunction = (children: any) =>
//   children.map((node: any, i: number) => {
//     if (Text.isText(node)) {
//       let text = (
//         <span dangerouslySetInnerHTML={{ __html: escapeHTML(node.text) }} />
//       );

//       // Handle other leaf types here...

//       return <Fragment key={i}>{text}</Fragment>;
//     }

//     if (!node) {
//       return null;
//     }

//     switch (node.type) {
//       case "h1":
//         return <h1 key={i}>{Serialize(node.children)}</h1>;
//       // Iterate through all headings here...
//       case "h6":
//         return <h6 key={i}>{Serialize(node.children)}</h6>;
//       case "blockquote":
//         return <blockquote key={i}>{Serialize(node.children)}</blockquote>;
//       case "ul":
//         return <ul key={i}>{Serialize(node.children)}</ul>;
//       case "ol":
//         return <ol key={i}>{Serialize(node.children)}</ol>;
//       case "li":
//         return <li key={i}>{Serialize(node.children)}</li>;
//       case "link":
//         return (
//           <a href={escapeHTML(node.url)} key={i}>
//             {Serialize(node.children)}
//           </a>
//         );

//       default:
//         return <p key={i}>{Serialize(node.children)}</p>;
//     }
//   });
