import { useQuery } from "react-query";
import { blogPosts } from "../../api";
import { useEffect } from "react";
import PageContainer from "../../components/PageContainer";
import { Flex, Grid, GridItem, Heading, Text } from "@chakra-ui/react";
// import Button from "../../components/Button";
import BlogCard from "../../components/blog/BlogCard";

const Blog = () => {
  const { data: postsData } = useQuery<BlogPostsResponse>({
    queryKey: "posts",
    queryFn: blogPosts,
  });

  useEffect(() => {
    if (postsData) console.log(postsData);
  }, [postsData]);

  return (
    <PageContainer>
      <Heading mt={20}>Grow With Papa</Heading>
      <Flex justify={"space-between"}>
        {/* <Flex mt={5} w={400}>
          <Input h={50} placeholder="Enter your email" rounded="full" />
          <Button
            onClick={() => null}
            styles={{ rounded: "full", ml: -108, mt: 0, h: 50, width: 130 }}
          >
            Subscribe
          </Button>
        </Flex> */}
        <Flex>
          <Text fontWeight={"bold"}>
            New blog posts every week to learn how to build wealth
          </Text>
        </Flex>
      </Flex>

      <Grid w='100%' mt={10} templateColumns="repeat(3, 1fr)" gap={6} rowGap={20}>
        {postsData?.docs.map((post, i: number) => (
          <GridItem key={i} w="100%" h="100%">
            <BlogCard {...post} />
          </GridItem>
        ))}
      </Grid>
    </PageContainer>
  );
};

export default Blog;
