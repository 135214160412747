import React from "react";
import {
  useColorModeValue,
  Text,
  // Grid,
  // GridItem,
  Flex,
  Heading,
  Stack,
} from "@chakra-ui/react";
import { useGreen, useLightGreen } from "../utils/theme";

const Hero2 = () => {
  const lightGreen = useLightGreen();
  const green = useGreen();
  const bg = useColorModeValue("#F5FBEF", "#013220");
  const textColor = useColorModeValue("black", "white");

  return (
    <>
      <Stack
        direction={{ base: "column", sm: 'column', md: "row" }}
        justify={"space-between"}
        bg={bg}
        rounded={10}
        p={{base: 10, md: 20}}
        mt={{ base: 10 }}
        color={textColor}
        
      >
        <Flex justify='center'>
          <Heading
            bgClip="text"
            bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
            fontSize={{ base: 60, md: 60 }}
            alignSelf={"center"}
          >
            1k+
          </Heading>
          <Text
            ml={5}
            alignSelf={"center"}
            fontSize={40}
            fontWeight="100"
          >
            Downloads
          </Text>
        </Flex>
        <Flex justify='center' ml={{}}>
          <Heading
            bgClip="text"
            bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
            fontSize={{ base: 60, md: 60 }}
            alignSelf={"center"}
          >
            20+
          </Heading>
          <Text
            ml={5}
            alignSelf={"center"}
            fontSize={40}
            fontWeight="100"
          >
            Episodes
          </Text>
        </Flex>
        <Flex justify='center' ml={{}}>
          <Heading
            bgClip="text"
            bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
            fontSize={{ base: 60, md: 60 }}
            alignSelf={"center"}
          >
            200+
          </Heading>
          <Text
            ml={5}
            alignSelf={"center"}
            fontSize={40}
            fontWeight="100"
          >
            Wealth Builders
          </Text>
        </Flex>
      </Stack>
      {/* <Grid
        templateColumns={{ base:"repeat(5, 1fr)", md:"repeat(3, 1fr)"}}
        // templateRows="repeat(3, 1fr)"
        mt={{ base: 10, md: 0 }}
      >
        <GridItem
          display={"flex"}
          flexDirection={"column"}
          colStart={{ base: 2, md: 1 }}
          colEnd={{ base:5, md:2}}
          rowStart={{ base: 1, md: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          p={{ base: 0, md: 10 }}
          mt={{ base: "0", md: "10" }}
          my={{base:10}}
        >
          {" "}
          <Text
            textAlign={"center"}
            w={"full"}
            bgClip="text"
            bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
            fontSize={80}
            fontWeight="700"
             borderRadius='10px'
            boxShadow={"lg"}
            px={4}
            mb={4}
            bg={bg}
            color={textColor}
          >
            1k
          </Text>
          <Text
            fontSize={12}
            fontWeight={"bold"}
            fontFamily={"footer"}
            textAlign={"center"}
            h={"100%"}
          >
            Over 1000 streams across platforms like Spotify and Apple.
          </Text>
        </GridItem>
        <GridItem
          display={"flex"}
          flexDirection={"column"}
          colStart={{ base: 2, md: 2 }}
          colEnd={{ base:5, md:3}}
          rowStart={2}
          alignItems={"center"}
          justifyContent={"center"}
          p={{ base: 0, md: 10 }}
          mt={{ base: "0", md: "10" }}
          my={{base:10}}
         
        >
          <Text
            w={"full"}
            textAlign={"center"}
            bgClip="text"
            bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
            fontSize={80}
            fontWeight="700"
             borderRadius='10px'
             boxShadow={"lg"}
            px={4}
            mb={4}
            bg={bg}
            color={textColor}
          >
            20
          </Text>
          <Text
            // display={"flex"}
            textAlign={"center"}
            fontSize={12}
            fontWeight={"bold"}
            fontFamily={"footer"}
            h={"100%"}

            // justifyContent="center"
          >
            Over 20 different guests speak about their journey in reaching
            finacnial wellness.
          </Text>
        </GridItem>
        <GridItem
          display={"flex"}
          flexDirection={"column"}
          colStart={{ base: 2, md: 3 }}
          colEnd={{ base:5, md:5}}
          rowStart={{ base: 3, md: 2 }}
          alignItems={"center"}
          justifyContent={"center"}
          p={{ base: 0, md: 10 }}
          mt={{ base: "0", md: "10" }}
          my={{base:10}}
        >
          <Text
            w={"full"}
            textAlign={"center"}
            bgClip="text"
            bgGradient={`linear(to-r, ${lightGreen}, ${green})`}
            fontSize={80}
            fontWeight="700"
            border={"none"}
             borderRadius='10px'
             boxShadow={"lg"}
            px={4}
            mb={4}
            bg={bg}
            color={textColor}
          >
            5
          </Text>
          <Text
            fontSize={12}
            fontWeight={"bold"}
            textAlign={"center"}
            fontFamily={"footer"}
            h={"100%"}
          >
            Over 5 years of experience in helping clients meet and exceed their
            financial goals.
          </Text>
        </GridItem>
      </Grid> */}
    </>
  );
};

export default Hero2;
