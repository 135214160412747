import { Flex, Heading, useColorModeValue } from "@chakra-ui/react";

const Hero3 = () => {
  // const bg = useColorModeValue("#F5FBEF", "#013220");
  const textColor = useColorModeValue("#A1C520", "#B3BF85");
  return (
    <>
      <Flex
        justifyContent={"center"}
        alignItems={"center"}
        flexDirection={{ base: "column", md: "row" }}
        my={"10"}
      >
        <Heading
          py={{ base: "4", md: "0" }}
          px={{ base: "0", md: "2" }}
          color={textColor}
          fontSize={{ base: "5xl", md: "4xl" }}
        >
          BUSINESS
        </Heading>
        <Heading
          py={{ base: "4", md: "0" }}
          px={{ base: "0", md: "2" }}
          color={textColor}
          fontSize={{ base: "5xl", md: "4xl" }}
          textAlign={{ base: "center", md: "left" }}
        >
          SELF-GROWTH
        </Heading>
        <Heading
          py={{ base: "4", md: "0" }}
          px={{ base: "0", md: "2" }}
          color={textColor}
          fontSize={{ base: "5xl", md: "4xl" }}
        >
          WEALTH
        </Heading>
      </Flex>
    </>
  );
};

export default Hero3;
