import React from "react";
import
  {
  Grid,
  Flex,
  GridItem,
  Heading,
  Text,
  useColorModeValue,
  // Link,
  Image,
  Box,
} from "@chakra-ui/react";
import { BsLinkedin } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";
import { BsYoutube } from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const Footer = () => {
  // const menuItems = [
  //   { title: "About", link: "/about" },
  //   { title: "Contact", link: "/contact" },
  // ];
  // const episodeItems = [
  //   { title: "Listen", link: "/listnNowt" },
  //   { title: "Spotify", link: "/spotify" },
  // ];
  // const resourceItems = [
  //   { title: "Resource2", link: "/resource2" },
  //   { title: "Resource3", link: "/resource3" },
  // ];
  // const navItemColor = useColorModeValue("gray.900", "gray.200");
  const bg = useColorModeValue("#F5FBEF", "#013220");
  return (
    <>
      <Grid
        templateColumns="repeat(7, 1fr)"
        templateRows={{ base: "repeat(1,1fr)", md: "repeat(0,0fr)" }}
        h={60}
        gap={2}
        bg={bg}
        bottom={0}
        left={0}
        right={0}
        as={"footer"}
        px={{ base: 0, md: 10 }}
        py={{ base: 0, md: 10 }}
        alignItems={"center"}
      >
        <GridItem
          pl={{ base: 5, md: 0 }}
          colSpan={{ base: 5, md: 2 }}
          colStart={{ lg: 1, md: 1 }}
          mt={{ base: 3.5, md: 0 }}
        >
          <Flex alignItems={"center"}>
            <Image
              fit={"contain"}
              display={{ base: "none", md: "flex" }}
              src={require("../assets/images/greensia_logo.png")}
            />
            <Text pl={{ lg: 2, md: 0 }} as={"b"} fontSize={"small"}>
              GWP PODCAST
            </Text>
          </Flex>

          <Text fontSize={"small"} fontWeight={"thin"} pb={1.5}>
            Growing with Papa is a journey <br /> that explores financial
            wellness.
          </Text>
        </GridItem>
        <GridItem
          rowStart={1}
          colStart={{ base: 6, md: 3, lg: 3 }}
          display={{ base: "grid", lg: "grid" }}
        >
          <Flex
            mt={{ base: 3.5 }}
            ml={15}
            flexDir={"column"}
            alignItems={"flex-start"}
          >
            {/* <Link>
              <Text
                fontSize={"small"}
                fontWeight={"bold"}
                color={navItemColor}
                mb={{ base: 0, md: "2" }}
              >
                Home
              </Text>
            </Link>
            {menuItems.map((item, i) => (
              <Link href={`${item.link.toLowerCase()}`} key={i}>
                <Text
                  fontSize={"small"}
                  fontWeight={"thin"}
                  color={navItemColor}
                  pb={1.5}
                >
                  {item.title}
                </Text>
              </Link>
            ))} */}
          </Flex>
        </GridItem>
        {/* <GridItem
          colStart={{ md: 4, lg: 4 }}
          display={{ base: "none", sm: "grid" }}
          px={{ base: 5, md: 0 }}
        >
          <Flex
            mt={{ base: 3.5 }}
            ml={15}
            flexDir={"column"}
            alignItems={"flex-start"}
          >
            <Link>
              <Text
                fontSize={"small"}
                fontWeight={"bold"}
                color={navItemColor}
                mb={{ base: 0, md: "2" }}
              >
                Episodes
              </Text>
            </Link>
            {episodeItems.map((item, i) => (
              <Link href={`${item.link.toLowerCase()}`} key={i}>
                <Text
                  fontSize={"small"}
                  pb={1.5}
                  color={navItemColor}
                  fontWeight={"thin"}
                >
                  {item.title}
                </Text>
              </Link>
            ))}
          </Flex>
        </GridItem> */}
        {/* <GridItem
          colStart={{ md: 5, lg: 5 }}
          display={{ base: "none", md: "grid" }}
        >
          <Flex mt={3.5} ml={15} flexDir={"column"} alignItems={"flex-start"}>
            <Link>
              <Text
                fontSize={"small"}
                fontWeight={"bold"}
                mb={"2"}
                color={navItemColor}
              >
                Resources
              </Text>
            </Link>
            {resourceItems.map((item, i) => (
              <Link href={`${item.link.toLowerCase()}`} key={i}>
                <Text
                  fontSize={"small"}
                  pb={1.5}
                  color={navItemColor}
                  fontWeight={"thin"}
                >
                  {item.title}
                </Text>
              </Link>
            ))}
          </Flex>
        </GridItem> */}
        <GridItem
          colStart={{ md: 6, base: 1 }}
          rowStart={{ base: 2, md: 1 }}
          colSpan={2}
          pl={{ md: 10, xl: 20 }}
          mt={{ md: 10, lg: 6 }}
        >
          <Heading
            fontSize={"small"}
            mb={"2"}
            display={{ base: "none", md: "grid" }}
          >
            About our mission
          </Heading>
          <Text
            pr={5}
            fontSize={"small"}
            pb={1.5}
            fontWeight={"thin"}
            display={{ base: "none", md: "grid" }}
          >
            Building wealth for a better tomorrow.
          </Text>
          <Box w={"full"} position={"relative"} mt={3.5}>
            <Flex
              position={"absolute"}
              bottom={{ base: 10, md: -5 }}
              left={{ base: 5, md: 0 }}
            >
              <Box mr={2}>
                <BsLinkedin size={20} />
              </Box>
              <Box mr={3}>
                <BsTwitter size={20} />
              </Box>
              <Box mr={3}>
                <FaTiktok size={20} />
              </Box>
              <Box mr={3}>
                <BsYoutube size={20} />
              </Box>
              <Box mr={3}>
                <BsInstagram size={20} />
              </Box>
            </Flex>
          </Box>
        </GridItem>
      </Grid>
    </>
  );
};

export default Footer;
