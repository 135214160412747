import PageContainer from "../components/PageContainer";

const Episodes = () => {
  return (
    <>
      <PageContainer>
        <></>
      </PageContainer>
    </>
  );
};

export default Episodes;
