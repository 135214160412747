import AboutPage from "../components/AboutPage";
import PageContainer from "../components/PageContainer";

const About = () => {
  return (
    <>
      <PageContainer>
        <AboutPage />
      </PageContainer>
    </>
  );
};

export default About;
