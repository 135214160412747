import React from "react";
import
  {
  Text,
  Flex,
  HStack,
  chakra,
  useColorModeValue,
  Link,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  useColorMode,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "../components/ColorModeSwitcher";
import { AiOutlineMenu } from "react-icons/ai";
import { useGreen, useLightGreen } from "../utils/theme";

const menuItems = [
  { title: "Home", link: "/" },
  { title: "About", link: "/about" },
  // { title: "Episodes", link: "/episodes" },
  { title: "Blog", link: "/blog" }
  // { title: "Episodes", link: "/episodes" },
];

const NavBar = () => {
  const navItemColor = useColorModeValue("gray.900", "gray.200");
  return (
    <>
      <chakra.header id="header">
        <Flex
          display={{ base: "none", sm: "flex" }}
          mb={5}
          w="100%"
          align="center"
          justify="space-between"
        >
          <Text fontWeight={800} fontSize={30} as="b">
            GWP PODCAST.{" "}
          </Text>
          <HStack as="nav" spacing="5">
            {menuItems.map((item, i) => (
              <Link href={`${item.link.toLowerCase()}`} key={i}>
                <Text color={navItemColor} as="b">
                  {item.title}
                </Text>
              </Link>
            ))}
            <ColorModeSwitcher />
          </HStack>
        </Flex>
      </chakra.header>
      <MobileNav />
    </>
  );
};

const MobileNav = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const lightGreen = useLightGreen();
  const green = useGreen();
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("Dark Mode", "Light Mode");
  const fontColor = useColorModeValue("#071E22", "white");
  return (
    <>
      <chakra.header id="header">
        <Flex
          display={{ base: "flex", sm: "none" }}
          mb={5}
          w="100%"
          align="center"
          justify="space-between"
        >
           <IconButton
            onClick={onOpen}
            size="lg"
            fontSize="lg"
            variant="ghost"
            color="current"
            aria-label={`Mobile hamburger menu`}
            icon={<AiOutlineMenu size={25} />}
          />
          <Text fontWeight={800} fontSize={30} as="b">
            GWP PODCAST.{" "}
          </Text>
         
          <ColorModeSwitcher />
        </Flex>
        <Modal size="full" isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent bgGradient={`linear(to-r, ${lightGreen}, ${green})`}>
            <ModalHeader color={fontColor}>Menu</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack>
                {menuItems.map((item, i) => (
                  <Link href={`${item.link.toLowerCase()}`} key={i}>
                    <Text fontSize={30} color={fontColor} as="b">
                      {item.title}
                    </Text>
                  </Link>
                ))}
                <Text as='b' fontSize={30} color={fontColor} onClick={toggleColorMode}>
                  {text}
                </Text>
              </VStack>
            </ModalBody>

          </ModalContent>
        </Modal>
      </chakra.header>
    </>
  );
};

export default NavBar;
