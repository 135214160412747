import {
  Flex,
  Heading,
  Text,
  useColorModeValue,
  Image,
} from "@chakra-ui/react";
import Button from "../components/Button";

const Hero1 = () => {
  const navItemColor = useColorModeValue("gray.900", "gray.200");
  return (
    <>
      <Flex
        flexDir={{ base: "column", md: "row" }}
        rounded={10}
        w={"100%"}
        mt={20}
      >
        <Image
          w={{ base: "100%", md: 500, lg: 500 }}
          src={require("../assets/images/heroshot1.png")}
        />
        <Flex
          mt={{ base: 10, md: 0 }}
          ml={{ base: 0, md: 10, lg: 20 }}
          flexDir={{ base: "column", md: "column" }}
        >
          <Heading
            alignSelf={{ base: "center", md: "flex-start" }}
            color={navItemColor}
            w={{ base: '100%', md: "100%" }}
            fontSize={{ base: 50, sm: 60, md: 50, lg: 70 }}
            textAlign={{ base: "center", md: "left" }}
          >
            A{" "}
            <Text as="span" color="#8BA431">
              better way{" "}
            </Text>
            to build wealth
          </Heading>
          <Button
            styles={{ w: { base: "100%", sm: "100%" } }}
            onClick={() => window.location.href = 'https://linktr.ee/gwppodcast'}
            
          >
            Listen Now
          </Button>
        </Flex>
      </Flex>
    </>
  );
};

export default Hero1;
