import React from "react";
import headshot from "../assets/images/center-headshot.png";
import moneyBro from "../assets/images/money-bro.png";
import sideshot from "../assets/images/side-headshot.png";
import PapaProfilePic from "../assets/images/PapaProfilePic.jpg";

import {
  Flex,
  Heading,
  Image,
  Text,
  Box,
  Button,
  VStack,
  HStack,
  Spacer,
} from "@chakra-ui/react";
import { useGreen, useLightGreen } from "../utils/theme";
import { useMediaQuery } from "@chakra-ui/react";
import aboutUsVideo from "../assets/images/aboutUsEpisode2.mp4";

const AboutPage = () => {
  const lightGreen = useLightGreen();
  const green = useGreen();
  const [isMobile] = useMediaQuery("(max-width: 480px)");
  const [isDesktop] = useMediaQuery("(min-width: 1550px)");
  return (
    <Flex flexDir={"column"} alignItems={"center"}>
      <Box w={"full"} maxW={"8xl"}>
        <Box>
          {isDesktop ? (
            <Flex
              flexDir={"row"}
              alignItems={"center"}
              bg={lightGreen}
              borderRadius={"2xl"}
              pt={"10"}
              position={"relative"}
              overflow={"hidden"}
            >
              <Image
                src={moneyBro}
                position={"absolute"}
                zIndex={"0"}
                boxSize={"650px"}
                objectFit={"contain"}
                left={"50"}
                top={"0"}
              />

              {isMobile ? (
                <Image
                  boxSize="300px"
                  objectFit="contain"
                  src={headshot}
                  mt={"5"}
                  zIndex={"2"}
                ></Image>
              ) : (
                <Image
                  boxSize="500px"
                  objectFit="contain"
                  src={sideshot}
                  mt={"5"}
                  zIndex={"2"}
                  ml={"80"}
                ></Image>
              )}

              <Flex flexDir={"column"} mx={"10"}>
                <Heading
                  as="h1"
                  size="4xl"
                  mt={"10"}
                  color={green}
                  zIndex={"2"}
                >
                  {" "}
                  Lets build wealth together.{" "}
                </Heading>
                <Text
                  fontFamily={"footer"}
                  as={"h5"}
                  fontSize={"sm"}
                  lineHeight={"1.8"}
                  mt={"5"}
                >
                  Join Papa Yaw on the GWP Podcast as he shares his wisdom and
                  insights on building wealth and achieving financial
                  independence.
                </Text>
                <Button
                  mt={"5"}
                  variant="outline"
                  borderRadius="2xl"
                  borderColor={green}
                  w={"50%"}
                  onClick={() =>
                    (window.location.href = "https://linktr.ee/gwppodcast")
                  }
                >
                  Listen now
                </Button>
              </Flex>
            </Flex>
          ) : (
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              bg={lightGreen}
              borderRadius={"2xl"}
              pt={"10"}
              position={"relative"}
              overflow={"hidden"}
            >
              <Heading
                as="h1"
                size="3xl"
                mt={"10"}
                textAlign={"center"}
                color={green}
                zIndex={"2"}
              >
                {" "}
                Lets build together.{" "}
              </Heading>
              <Button
                mt={"5"}
                variant="outline"
                borderRadius="2xl"
                borderColor={green}
                onClick={() =>
                  (window.location.href = "https://linktr.ee/gwppodcast")
                }
              >
                Listen now
              </Button>
              {isMobile ? (
                <Image
                  boxSize="300px"
                  objectFit="contain"
                  src={headshot}
                  mt={"5"}
                  zIndex={"2"}
                ></Image>
              ) : (
                <Image
                  boxSize="500px"
                  objectFit="contain"
                  src={sideshot}
                  mt={"5"}
                  zIndex={"2"}
                ></Image>
              )}
            </Flex>
          )}
        </Box>

        {!isDesktop ? (
          <VStack spacing={4}>
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              borderRadius={"2xl"}
              pt={"10"}
              fontFamily={"footer"}
            >
              <Box
                p={{ base: 5, md: 0 }}
                w={"full"}
                maxW={"4xl"}
                alignItems={"center"}
              >
                 <Box
                as="video"
                controls
                autoPlay
                muted
                src={aboutUsVideo}
                borderRadius={"2xl"}
                w={"full"}
                maxW={"4xl"}
                alignItems={"center"}
              ></Box>
                <Heading as={"h3"} size={"md"} mt={"5"} color={green}>
                  OUR MISSION
                </Heading>
                <Heading as={"h5"} size={"sm"} lineHeight={"1.8"} mt={"5"}>
                  Welcome to the GWP Podcast, your ultimate destination for
                  financial freedom and wealth building.
                </Heading>
                <Text fontSize="md" mt={"2"} lineHeight={"1.8"}>
                  Our team of experienced wealth builders is dedicated to
                  providing you with actionable advice and insights on
                  investing, personal finance, and entrepreneurship. With a
                  focus on empowering you with the knowledge and tools you need
                  to achieve your financial goals, we cover a wide range of
                  topics to help you navigate the challenges and obstacles on
                  your path to financial independence.
                </Text>

                <Flex flexDir={"column"} alignItems={"center"}>
                  <Image
                    borderRadius={"2xl"}
                    boxSize="500px"
                    mt={"5"}
                    objectFit="cover"
                    src={PapaProfilePic}
                  ></Image>
                </Flex>

                <Heading as={"h3"} size={"md"} mt={"5"} color={green}>
                  OUR HOST
                </Heading>
                <Heading as={"h5"} size={"sm"} lineHeight={"1.8"} mt={"5"}>
                  Papa Yaw Quainoo, shares his inspiring story and expertise on
                  building wealth and achieving financial independence.
                </Heading>
                <Text fontSize="md" mt={"5"} lineHeight={"1.8"}>
                  Growing up, Papa Yaw had no understanding of wealth and how to
                  manage money, but that all changed when he discovered books
                  that educated him about finances. Determined to change his
                  financial future, Papa is passionate about sharing his
                  knowledge and experience with others. Join our community of
                  wealth builders today and start building your own path to
                  financial freedom. Optimize your financial future with GWP
                  Podcast.
                </Text>
              </Box>
            </Flex>
          </VStack>
        ) : (
          <Flex
            flexDir={"row"}
            alignItems={"top"}
            borderRadius={"2xl"}
            mt={"10"}
            fontFamily={"footer"}
            // bg={"blue"}
          >
            <Spacer />

            <HStack spacing={"30px"} justifyItems={"center"}>
              <Box
                as="video"
                controls
                autoPlay
                muted
                src={aboutUsVideo}
                borderRadius={"2xl"}
                w={"full"}
                maxW={"4xl"}
                alignItems={"center"}
              ></Box>

              <Box
                // bg='yellow.200'

                px={"10"}
                pl={"10"}
                py={"5"}
                w={"full"}
                maxW={"4xl"}
                alignItems={"center"}
              >
                
                <Heading as={"h3"} size={"md"} mt={"5"} color={green}>
                  OUR MISSION
                </Heading>
                <Heading as={"h5"} size={"sm"} lineHeight={"1.8"} mt={"5"}>
                  Welcome to the GWP Podcast, your ultimate destination for
                  financial freedom and wealth building.
                </Heading>
                <Text fontSize="md" mt={"5"} lineHeight={"1.8"}>
                  Our team of experienced wealth builders is dedicated to
                  providing you with actionable advice and insights on
                  investing, personal finance, and entrepreneurship. With a
                  focus on empowering you with the knowledge and tools you need
                  to achieve your financial goals, we cover a wide range of
                  topics to help you navigate the challenges and obstacles on
                  your path to financial independence.
                </Text>

                {!isDesktop && (
                  <Flex flexDir={"column"} my={"2"} alignItems={"center"}>
                    <Image
                      boxSize="400px"
                      objectFit="contain"
                      src={moneyBro}
                    ></Image>
                  </Flex>
                )}
              </Box>
             
            </HStack>
          </Flex>
        )}

        {!isDesktop ? (
          <VStack spacing={4}>
            <Flex
              flexDir={"column"}
              alignItems={"center"}
              borderRadius={"2xl"}
              pt={"10"}
              fontFamily={"footer"}
            >
              <Box
                p={{ base: 5, md: 0 }}
                w={"full"}
                maxW={"4xl"}
                alignItems={"center"}
              ></Box>
            </Flex>
          </VStack>
        ) : (
          <Flex
            flexDir={"row"}
            alignItems={"top"}
            borderRadius={"2xl"}
            mt={"10"}
            fontFamily={"footer"}
            // bg={"blue"}
          >
            <HStack spacing={"auto"} justifyItems={"center"}>
              <Box
                // bg='yellow.200'

                pr={"10"}
                py={"5"}
                w={"full"}
                maxW={"3xl"}
                alignItems={"center"}
              >
                <Heading as={"h3"} size={"md"} mt={"5"} color={green}>
                  OUR HOST
                </Heading>
                <Heading as={"h5"} size={"sm"} lineHeight={"1.8"} mt={"5"}>
                  Papa Yaw Quainoo, shares his inspiring story and expertise on
                  building wealth and achieving financial independence.
                </Heading>
                <Text fontSize="md" mt={"5"} lineHeight={"1.8"}>
                  Growing up, Papa Yaw had no understanding of wealth and how to
                  manage money, but that all changed when he discovered books
                  that educated him about finances. Determined to change his
                  financial future, Papa is passionate about sharing his
                  knowledge and experience with others. Join our community of
                  wealth builders today and start building your own path to
                  financial freedom. Optimize your financial future with GWP
                  Podcast.
                </Text>

                {!isDesktop && (
                  <Flex flexDir={"column"} my={"2"} alignItems={"center"}>
                    <Image
                      boxSize="400px"
                      objectFit="contain"
                      src={moneyBro}
                    ></Image>
                  </Flex>
                )}
              </Box>

              <Flex w={"auto"} justifyContent={"center"}>
                {" "}
                <Box>
                  <Image
                    borderRadius={"2xl"}
                    boxSize="800px"
                    objectFit="cover"
                    src={PapaProfilePic}
                  ></Image>
                </Box>
              </Flex>
            </HStack>
          </Flex>
        )}
      </Box>
    </Flex>
  );
};

export default AboutPage;
